import { Spinner } from 'reactstrap';
import cn from 'classnames';
import styles from '@/app/components/shared/Loader/Loader.module.scss';

type Props = {
  /**
   * Indicates whether the loader is active
   */
  isLoading: boolean;
  /**
   * Size of the spinner width and height in px
   */
  size?: number;
  /**
   * Minimum height of the container in px
   */
  minHeight?: number;
  /**
   * Indicates whether the loader is full screen
   */
  isFullScreen?: boolean;
  /**
   * Custom class name for the loader container
   */
  className?: string;
};

/**
 * Loader UI component for user interaction
 */
export default function Loader({
  isLoading = false,
  size = 16,
  minHeight,
  isFullScreen,
  className = '',
}: Props) {
  const containerStyle = minHeight ? { minHeight: `${minHeight}px` } : {};
  const spinnerStyle = { width: size, height: size };

  const renderSpinner = () => <>{isLoading && <Spinner style={spinnerStyle} />}</>;

  return (
    <div
      style={containerStyle}
      className={cn(`${styles.loaderContainer} ${className}`, {
        [styles.isFullScreen]: isFullScreen,
      })}
    >
      {renderSpinner()}
    </div>
  );
}
